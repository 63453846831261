import React, { useState, useEffect } from 'react'
import SlideToggle from 'react-slide-toggle'
import { Link } from 'gatsby'
import ContactButton from './ContactButton'

const getScrollValueFromTop = () => {
  return Math.max(
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop
  )
}

const Header = () => {
  const [showHeader, setShowHeader] = useState(true)
  const [fixHeader, setFixHeader] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const onScroll = () => {
    const position = getScrollValueFromTop()
    if (position >= 500) {
      setFixHeader(true)
      setTimeout(() => {
        setShowHeader(true)
      }, 50)
    } else {
      setShowHeader(false)
      setTimeout(() => {
        setFixHeader(false)
        setShowHeader(true)
        setShowHeader(false)
      }, 50)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => document.removeEventListener('scroll', onScroll)
  })

  return (
    <header
      className={`${showHeader ? 'show' : ''} ${fixHeader ? 'fixed' : ''}`}
    >
      <div className="header-wrapper clearfix">
        <Link className="logo" to={`/`}>
          <img src="/assets/images/common/logo-rgb.png" alt="logo-rgb" />
        </Link>
        <div className="menu">
          <ul className="nav">
            <li>
              <Link to={`/`}>トップ</Link>
            </li>
            <li id="service">
              <Link to={`/service`}>製品／サービス</Link>
              <div className="submenu-pop">
                <div className="row">
                  <div className="submenu-header">製品・サービス</div>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="inner">
                      <Link to={`/service/internet/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-netdeal.webp"
                          />
                          <img
                            src="/assets/images/common/icon-netdeal.png"
                            alt=""
                          />
                        </picture>
                        ネットワーク対応精算機
                      </Link>
                    </div>
                    <div className="inner">
                      <Link to={`/service/remote/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-remotecontrol.webp"
                          />
                          <img
                            src="/assets/images/common/icon-remotecontrol.png"
                            alt=""
                          />
                        </picture>
                        遠隔管理システム
                      </Link>
                    </div>
                    <div className="inner">
                      <Link to={`/service/lockless/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-lockless.webp"
                          />
                          <img
                            src="/assets/images/common/icon-lockless.png"
                            alt=""
                          />
                        </picture>
                        ナンバー認識システム「ロックレス」
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="inner">
                      <Link to={`/service/gate/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-gatesystem.webp"
                          />
                          <img
                            src="/assets/images/common/icon-gatesystem.png"
                            alt=""
                          />
                        </picture>
                        ゲート式駐車場管理システム
                      </Link>
                    </div>
                    <div className="inner">
                      <Link to={`/service/unit/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-lockunit.webp"
                          />
                          <img
                            src="/assets/images/common/icon-lockunit.png"
                            alt=""
                          />
                        </picture>
                        ロック板ユニット
                      </Link>
                    </div>
                    <div className="inner">
                      <Link to={`/service/bike/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-bikelock.webp"
                          />
                          <img
                            src="/assets/images/common/icon-bikelock.png"
                            alt=""
                          />
                        </picture>
                        バイク用ロック板ユニット
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="inner">
                      <Link to={`/service/bicycle/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-bicycle.webp"
                          />
                          <img
                            src="/assets/images/common/icon-bicycle.png"
                            alt=""
                          />
                        </picture>
                        駐輪管理システム
                      </Link>
                    </div>
                    <div className="inner">
                      <Link to={`/service/camera/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-surveillancecamera.webp"
                          />
                          <img
                            src="/assets/images/common/icon-surveillancecamera.png"
                            alt=""
                          />
                        </picture>
                        防犯カメラ
                      </Link>
                    </div>
                    <div className="inner">
                      <Link to={`/service/qtnetservice/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-qtnet.webp"
                          />
                          <img
                            src="/assets/images/common/icon-qtnet.png"
                            alt=""
                          />
                        </picture>
                        QT-net サービス
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="inner">
                      <Link to={`/service/qtnet/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-qtnet.webp"
                          />
                          <img
                            src="/assets/images/common/icon-qtnet.png"
                            alt=""
                          />
                        </picture>
                        QT-net
                      </Link>
                    </div>
                    <div className="inner">
                      <Link to={`/service/smartpay/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-smartpay.webp"
                          />
                          <img
                            src="/assets/images/common/icon-smartpay.png"
                            alt=""
                          />
                        </picture>
                        SmartPay
                      </Link>
                    </div>
                    <div className="inner">
                      <Link to={`/service/support/`}>
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-support.webp"
                          />
                          <img
                            src="/assets/images/common/icon-support.png"
                            alt=""
                          />
                        </picture>
                        管理サポート
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="container">
                    <div className="inner">
                      <a
                        href="/assets/files/itc-product-maintenance.pdf"
                        target="_blank"
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcset="/assets/images/common/icon-setting.webp"
                          />
                          <img
                            src="/assets/images/common/icon-setting.png"
                            alt=""
                          />
                        </picture>
                        生産終了及び保守期限一覧表
                      </a>
                    </div>
                    <div className="inner blanc"></div>
                    <div className="inner blanc"></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to={`/about/`}>会社概要</Link>
            </li>
            <li>
              <Link to={`/contact`}>お問い合わせ</Link>
            </li>
            <li>
              <a
                href="https://herp.careers/v1/itec"
                target="_blank"
                rel="noreferrer"
              >
                採用情報
              </a>
            </li>
          </ul>
          <div className="box login">
            <Link to={`/login/`}>会員向けログイン</Link>
          </div>
          <icon
            id="menu"
            className="sp-only"
            onClick={(e) => {
              setIsDrawerOpen(!isDrawerOpen)
            }}
          />
        </div>
      </div>

      <div id="sp-gnav" className={isDrawerOpen ? 'show' : ''}>
        <div className="gnav-header">
          <Link to={`/`}>
            <img src="/assets/images/common/logo-rgb.png" alt="logo-rgb" />
          </Link>
          <ContactButton />
          <icon
            id="gnav-close"
            onClick={(e) => {
              setIsDrawerOpen(!isDrawerOpen)
            }}
          ></icon>
        </div>
        <div className="inner">
          <ul>
            <li>
              <Link to={`/`}>トップページ</Link>
            </li>
            <li>
              <Link to={`/login`}>会員向けログイン</Link>
            </li>
            <li>
              <Link to={`/news`}>トピックス</Link>
            </li>
            <SlideToggle
              collapsed
              render={({ toggle, setCollapsibleElement }) => (
                <div className="my-collapsible">
                  <li
                    className={`parent has-child`}
                    onClick={toggle}
                    aria-hidden="true"
                  >
                    製品／サービス
                  </li>
                  <div
                    className="my-collapsible__content"
                    ref={setCollapsibleElement}
                  >
                    <div className="my-collapsible__content-inner">
                      <div className="child" style={{ display: 'block' }}>
                        <Link to={`/service/internet/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-netdeal.png"
                              alt=""
                            />
                            ネットワーク対応精算機
                          </li>
                        </Link>
                        <Link to={`/service/remote/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-remotecontrol.png"
                              alt=""
                            />
                            遠隔管理システム
                          </li>
                        </Link>
                        <Link to={`/service/lockless/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-lockless.png"
                              alt=""
                            />
                            ナンバー認識システム「ロックレス」
                          </li>
                        </Link>
                        <Link to={`/service/gate/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-gatesystem.png"
                              alt=""
                            />
                            ゲート式駐車場管理システム
                          </li>
                        </Link>
                        <Link to={`/service/unit/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-lockunit.png"
                              alt=""
                            />
                            ロック板ユニット
                          </li>
                        </Link>
                        <Link to={`/service/bike/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-bikelock.png"
                              alt=""
                            />
                            バイク用ロック板ユニット
                          </li>
                        </Link>
                        <Link to={`/service/bicycle/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-bicycle.png"
                              alt=""
                            />
                            駐輪管理システム
                          </li>
                        </Link>
                        <Link to={`/service/camera/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-surveillancecamera.png"
                              alt=""
                            />
                            防犯カメラ
                          </li>
                        </Link>
                        <Link to={`/service/qtnetservice/`}>
                          <li className="qt">
                            <img
                              src="/assets/images/common/icon-qtnet.png"
                              alt=""
                            />
                            QT-netサービス
                          </li>
                        </Link>
                        <Link to={`/service/qtnet/`}>
                          <li className="qt">
                            <img
                              src="/assets/images/common/icon-qtnet.png"
                              alt=""
                            />
                            QT-net
                          </li>
                        </Link>
                        <Link to={`/service/smartpay/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-smartpay.png"
                              alt=""
                            />
                            Smart Pay
                          </li>
                        </Link>
                        <Link to={`/service/support/`}>
                          <li>
                            <img
                              src="/assets/images/common/icon-support.png"
                              alt=""
                            />
                            管理サポート
                          </li>
                        </Link>
                        <a
                          href="/assets/files/itc-product-maintenance.pdf"
                          target="_blank"
                        >
                          <li>
                            <img
                              src="/assets/images/common/icon-setting.png"
                              alt=""
                            />
                            生産終了及び保守期限一覧表
                          </li>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
            <li>
              <Link to={`/about`}>会社概要</Link>
            </li>
            <li>
              <Link to={`/mvv`}>ミッション・ビジョン・バリュー</Link>
            </li>
            <li>
              <Link to={`/faq`}>よくあるご質問</Link>
            </li>
            <li>
              <Link to={`/contact`}>お問い合わせ</Link>
            </li>
            <li>
              <a
                href="https://herp.careers/v1/itec"
                target="_blank"
                rel="noreferrer"
              >
                採用情報
              </a>
            </li>
            <li>
              <Link to={`/sitemap`}>サイトマップ</Link>
            </li>
            <li>
              <a href="/assets/files/privacy.pdf" target="_blank">
                個人情報・訂正・削除依頼書
              </a>
            </li>
            <li>
              <Link to={`/policy`}>プライバシーポリシー</Link>
            </li>
            <li>
              <Link to={`/privacy`}>個人情報の取扱について</Link>
            </li>

            <li>
              <Link to={`/service/qtnetservice/`}>QT-net</Link>
            </li>
            <li>
              <a
                href="http://www.i-tech-corp.co.jp/parksearch_pc"
                target="_blank"
                rel="noreferrer"
              >
                QT-net駐車場検索
              </a>
            </li>
            <li>
              <a
                href="https://www.itcbb.net/gns/McpLogin"
                target="_blank"
                rel="noreferrer"
              >
                駐車場運営会社様ログイン
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
