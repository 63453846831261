import React from 'react'
import { Link } from 'gatsby'

const ContactButton = () => (
  <div className="common-btn">
    <Link to={`/contact/`}>お問い合わせ</Link>
  </div>
)

export default ContactButton
