import React from 'react'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer>
      <div className="stt-icon">
        <Link to={`#`}>
          <img
            className="arrow"
            src="/assets/images/common/arrow-up-w.svg"
            alt=""
          />
        </Link>
      </div>
      <div className="wrapper clearfix">
        <div className="info">
          <Link to={`/`}>
            <img
              className="logo"
              src="/assets/images/common/logo-rgb.png"
              alt="logo-rgb"
            />
          </Link>
          <div className="clear sp-only"></div>
          <div className="icons">
            <Link to={`https://privacymark.jp/`}>
              <picture>
                <source
                  type="image/webp"
                  srcset="/assets/images/common/privacy.webp"
                />
                <img
                  className="privacy"
                  src="/assets/images/common/privacy.png"
                  alt="privacy"
                />
              </picture>
            </Link>
          </div>
        </div>
        <ul className="left">
          <li>
            <Link to={`/news`}>トピックス</Link>
          </li>
          <li>
            <Link to={`/service`}>製品／サービス</Link>
            <ul>
              <li>
                <Link to={`/service/internet`}>ネットワーク対応精算機</Link>
              </li>
              <li>
                <Link to={`/service/remote`}>遠隔管理システム</Link>
              </li>
              <li>
                <Link to={`/service/lockless`}>
                  ナンバー認識システム「ロックレス」
                </Link>
              </li>
              <li>
                <Link to={`/service/gate`}>ゲート式駐車場管理システム</Link>
              </li>
              <li>
                <Link to={`/service/unit`}>ロック板ユニット</Link>
              </li>
              <li>
                <Link to={`/service/bike`}>バイク用ロック板ユニット</Link>
              </li>
              <li>
                <Link to={`/service/bicycle`}>駐輪管理システム</Link>
              </li>
              <li>
                <Link to={`/service/camera`}>防犯カメラ</Link>
              </li>
              <li>
                <Link to={`/service/qtnetservice`}>QT-netサービス</Link>
              </li>
              <li>
                <Link to={`/service/qtnet`}>QT-net</Link>
              </li>
              <li>
                <Link to={`/service/smartpay`}>Smart Pay</Link>
              </li>
              <li>
                <Link to={`/service/support`}>管理サポート</Link>
              </li>
              <li>
                <a
                  href="/assets/files/itc-product-maintenance.pdf"
                  target="_blank"
                >
                  生産終了及び保守期限一覧表
                </a>
              </li>
            </ul>
          </li>
          <li>
            <Link to={`/whitepaper`}>カタログダウンロード</Link>
          </li>
        </ul>
        <ul className="right">
          <li>
            <Link to={`/about`}>会社概要</Link>
          </li>
          <li>
            <Link to={`/mvv`}>ミッション・ビジョン・バリュー</Link>
          </li>
          <li>
            <Link to={`/faq`}>よくあるご質問</Link>
          </li>
          <li>
            <Link to={`/contact`}>お問い合わせ</Link>
          </li>
          <li>
            <a
              href="https://herp.careers/v1/itec"
              target="_blank"
              rel="noreferrer"
            >
              採用情報
            </a>
          </li>
          <li>
            <Link to={`/sitemap`}>サイトマップ</Link>
          </li>
          <li>
            <a href="/assets/files/privacy.pdf" target="_blank">
              個人情報・訂正・削除依頼書
            </a>
          </li>
          <li>
            <Link to={`/policy`}>プライバシーポリシー</Link>
          </li>
          <li>
            <a href="/assets/files/qtnet_term.pdf" target="_blank">
              QT-net会員規約
            </a>
          </li>
          <li>
            <Link to={`/service/qtnet`}>QT-net</Link>
          </li>
          <li>
            <a
              href="http://www.i-tech-corp.co.jp/parksearch_pc"
              target="_blank"
              rel="noreferrer"
            >
              QT-net駐車場検索
            </a>
          </li>
          <li>
            <a
              href="https://www.itcbb.net/gns/McpLogin"
              target="_blank"
              rel="noreferrer"
            >
              駐車場運営会社様ログイン
            </a>
          </li>
          <li>
            <a
              href="https://qt-net.itcbb.net/signin"
              target="_blank"
              rel="noreferrer"
            >
              QT-net個人会員様ログイン
            </a>
          </li>
          <li>
            <a
              href="https://biz-adm.itcbb.net/internal/login"
              target="_blank"
              rel="noreferrer"
            >
              QT-net法人会員様ログイン
            </a>
          </li>
        </ul>
        <div className="clear"></div>
        <div className="copy">COPYRIGHT © ITC. All Rights Reserved.</div>
      </div>
    </footer>
  )
}

export default Footer
